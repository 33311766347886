import logo from './logo.svg';
import './App.css';
import { LandingScreen } from './screens/Landing/LandingScreen';
import { Form } from './screens/Form/Form';
import Success from './screens/Success/Success';
import { ToastContainer } from 'react-toastify';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { apiKey } from './apiKey';

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "lavista-12948.firebaseapp.com",
  projectId: "lavista-12948",
  storageBucket: "lavista-12948.appspot.com",
  messagingSenderId: "5613670129",
  appId: "1:5613670129:web:27c341743e887fcaf76350"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);
const Stack = createNativeStackNavigator();

function App() {
  return (
    <div className="App">
      <ToastContainer
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="light"
            />
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Landing">
        <Stack.Screen name="Landing" component={LandingPage} options={{title:'Lavista Application'}}/>
        <Stack.Screen name="Form" component={FormPage} options={{title:'Lavista Application'}}/>
        <Stack.Screen name="Success" component={SuccessPage} options={{title:'Lavista Application'}}/>
      </Stack.Navigator>
    </NavigationContainer>
    </div>
  );
}

const LandingPage = ({navigation}) => {
  return <LandingScreen navigation={navigation}/>;
}

const FormPage = ({route, navigation}) => {
  const {district, project} = route.params;
  return <Form navigation={navigation} district={district['label']} project={project['label']}/>;
}

const SuccessPage = ({route, navigation}) => {
  const {appNo} = route.params;
  return <Success navigation={navigation} appNo={appNo}/>;
}

export default App;
