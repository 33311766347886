import React, { useState, useEffect } from "react";
import "./LandingScreen.css";
import 'react-dropdown/style.css'
import Select from "react-select"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        borderColor: 'transparent',
        border: 'none',
        backgroundColor: 'transparent',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#000' : '#888',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        transition: 'transform 0.3s',
        transform: 'rotate(0deg)',
        border: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '20px',
    }),
    option: (provided, state) => ({
        ...provided,
        borderRadius: '14px',
        fontWeight: state.isFocused ? "500" : "400",
        color: state.isFocused ? "black" : "#666666",
        textAlign: "left",
        backgroundColor: "transparent"
    }),
    value: (provided) => ({
        ...provided,
        borderRadius: '20px',
        backgroundColor: 'transparent',
    }),
    singleValue: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
    }),
    valueContainer: (provided) => ({
        ...provided,
        backgroundColor: "transparent"
    }),
};

export const LandingScreen = (props) => {
    const [isTab1, setTab1] = useState(true);
    const [isFade, setFade] = useState(false);
    const [district, setDistrict] = useState(null);
    const [project, setProject] = useState(null);

    const handleDropdownOpen = (isOpen) => {
        if (isOpen) {
            customStyles.dropdownIndicator.transform = 'rotate(180deg)';
        } else {
            customStyles.dropdownIndicator.transform = 'rotate(0deg)';
        }
    };

    const coastalDistricts = [
        {
            value: 'northCoast', label: 'North Coast', projects: [
                { value: 'Bayeast', label: 'La Vista Bay East' },
                { value: 'hikma', label: 'La Vista Ras El Hikma' },
                { value: 'cascada', label: 'La Vista Cascada' },
            ]
        },
        {
            value: 'ainElSokhna', label: 'Ain El Sokhna', projects: [
                { value: 'gardens', label: 'La Vista Gardens' },
                { value: 'ray', label: 'La Vista Ray' },
                { value: 'topaz', label: 'La Vista Topaz' },
                { value: 'vista4', label: 'La Vista 4' },
                { value: 'vista5', label: 'La Vista 5' },
                { value: 'vista6', label: 'La Vista 6' },
                { value: 'vista4', label: 'La Vista 7' },
            ]
        }
    ];

    const residentialDistricts = [
        {
            value: 'newCairo', label: 'New Cairo', projects: [
                { value: 'oro', label: 'El Patio Oro' },
                { value: 'patio7', label: 'El Patio 7' },
            ]
        },
        {
            value: 'newCapital', label: 'New Capital', projects: [
                { value: 'city', label: 'La Vista City' },
            ]
        },
        {
            value: 'shorouk', label: 'El Shorouk City', projects: [
                { value: 'casa', label: 'El Patio Casa' },
                { value: 'prime', label: 'El Patio Prime' },
                { value: '5East', label: 'El Patio 5 East' },
            ]
        },
        {
            value: 'sheikhzayed', label: '6 october', projects: [
                { value: 'patio6', label: 'El Patio 6' },
            ]
        }, 
    ];

    const handleDistrictChange = (option) => {
        if (!district || option.value !== district.value) {
            setDistrict(option);
            setProject(null);
        }
    }

    const handleTabChange = (tab1) => {
        setTab1(tab1);
        setProject(null);
        setDistrict(null);
    }

    const toForm = () => {
        if (!district) {
            toast.dismiss();
            toast.error('Please select district', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (!project) {
            toast.dismiss();
            toast.error('Please select project', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        setFade(true);
        setTimeout(() => {
            document.getElementById('content').style.display = "none";
        }, 380);
        setTimeout(() => {
            document.getElementById('background').style.display = "none";
            setTimeout(() => {
                props.navigation.navigate("Form", { district: district, project: project });
                setFade(false);
                document.getElementById('content').style.display = "flex";
                document.getElementById('background').style.display = "block";
            }, 150);
        }, 580);
    }


    return <div className="bgColor">
         <div id="background" className={(!isTab1 ? "background res-bg" : "background coastal-bg") +(isFade ? " fadeBg" : "")}>
        <div id="content" className={isFade ? "content fade" : `content`}>
            <img className="logo" alt="White" src={require("../../assets/logo.png")} />
            <div className="form">
                <div className={isTab1 ? "tab1 tabEnabled" : "tab1"}>
                    <div className={isTab1 ? "tabTextEnabled" : "tabTextDisabled"} onClick={() => handleTabChange(true)}>COASTAL</div>
                </div>
                <div className={!isTab1 ? "tab2 tabEnabled" : "tab2"}>
                    <div className={!isTab1 ? "tabTextEnabled" : "tabTextDisabled"} onClick={() => handleTabChange(false)}>RESIDENTIAL</div>
                </div>
                <div className="selectionBox">
                    <div className="search-type district">DISTRICTS</div>
                    <div className="districtSelect">
                        <Select
                            options={isTab1 ? coastalDistricts : residentialDistricts}
                            styles={customStyles}
                            placeholder="Select District"
                            value={district}
                            role="menu"
                            isSearchable={false}
                            onChange={handleDistrictChange}
                            onMenuOpen={() => handleDropdownOpen(true)}
                            onMenuClose={() => handleDropdownOpen(false)}
                        />
                    </div>
                    <div className="projectSelect">
                        <Select
                            options={district ? district.projects : []}
                            styles={customStyles}
                            placeholder="Select Project"
                            noOptionsMessage={() => "No District Selected"}
                            value={project}
                            isSearchable={false}
                            onChange={(option) => setProject(option)}
                            onMenuOpen={() => handleDropdownOpen(true)}
                            onMenuClose={() => handleDropdownOpen(false)}
                        />
                    </div>
                    <div className="search-type project">PROJECTS</div>
                    <div className="search-button" onClick={toForm} />
                </div>
            </div>
        </div>
    </div>
    </div>
}