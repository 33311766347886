import "./Form.css";
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Select from "react-select"
import Dropzone from "react-dropzone";
import { doc, setDoc, getFirestore, Timestamp, addDoc, collection } from "firebase/firestore";
import { firebaseApp, storage } from "../../App";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import EmailTemplate from "../EmailTemplate/EmailTemplate";


export const Form = (props) => {
    const [formStep, setFormStep] = useState(1);
    const [fullName, setFullName] = useState("");
    const [jobCompany, setJob] = useState("");
    const [dobDay, setDOBDay] = useState("");
    const [dobMonth, setDOBMonth] = useState("");
    const [dobYear, setDOBYear] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumber2, setPhoneNumber2] = useState("");
    const [socialPlatform, setSocialPlatform] = useState("");
    const [socialProfile, setSocialProfile] = useState("");
    const [nationalID, setNationalID] = useState("");
    const [spouseID, setSpouseID] = useState("");
    const [childrenAge, setChildrenAge] = useState("");
    const [comments, setComments] = useState("");

    const [idFiles, setIDFiles] = useState([]);
    const [spouseIDFiles, setSpouseIDFiles] = useState([]);
    const [childrenIDFiles, setChildrenIDFiles] = useState([]);
    const [familyFiles, setFamilyFiles] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(null);


    const maritalOptions = [
        { value: "single", label: "Single" },
        { value: "married", label: "Married" },
        { value: "divorced", label: "Divorced" },
        { value: "widowed", label: "Widowed" },
    ]

    const socialOptions = [
        { value: "facebook", label: "Facebook" },
        { value: "instagram", label: "Instagram" }
    ]

    const ageOptions = [
        { value: "none", label: "None" },
        { value: "over", label: "Above 16" },
        { value: "under", label: "Under 16" },
    ]

    const dayOptions = () => {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            days.push({ value: i, label: i })
        }
        return days;
    };

    const monthOptions = () => {
        let months = [];
        for (let i = 1; i <= 12; i++) {
            months.push({ value: i, label: i })
        }
        return months;
    }

    const handleDropdownOpen = (isOpen) => {

        customStyles.dropdownIndicator.transform = 'rotate(180deg)';

    };

    const handleDropdownClose = () => {
        customStyles.dropdownIndicator.transform = 'rotate(0deg)';
    }

    const handleNameChange = (event) => {
        setFullName(event.target.value);
    }
    const handleJobCompanyChange = (event) => {
        setJob(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    }
    const handlePhone2Change = (event) => {
        setPhoneNumber2(event.target.value);
    }
    const handleSocialChange = (event) => {
        setSocialProfile(event.target.value);
    }
    const handleNationalIDChange = (event) => {
        setNationalID(event.target.value);
    }
    const handleSpouseIDChange = (event) => {
        setSpouseID(event.target.value);
    }
    const handleCommentChange = (event) => {
        setComments(event.target.value);
    }

    const toastMe = (string) => {
        const errorMsg = string ?? 'Please fill in all fields';
        const id = toast.error(errorMsg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const onNext = (e, formId) => {
        e.preventDefault();
        const form = document.getElementById(formId);
        const valid = form.checkValidity();
        if (!valid) {
            toastMe();
            return;
        }
        if (formStep === 1 && !validateForm1()) {
            return;
        } else if (formStep === 2 && !validateForm2()) {
            return;
        }
        setFormStep((prevStep) => prevStep + 1);
    }

    const onPrevious = (e) => {
        e.preventDefault();
        if (formStep == 1) {
            toast.dismiss();
            props.navigation.navigate("Landing");
            return;
        }
        setFormStep((prevStep) => prevStep - 1);
    }


    const getForm1 = () => {
        return <form id="form1">
            <div className="formContainer">

                <h2>Personal Data</h2>
                <label htmlFor="fullName" >Full Name</label>
                <input className="inputBorder" type="text" id="fullName" name="fullName" value={fullName} onChange={handleNameChange} required />
                <label htmlFor="jobTitle">Job Title / Company Name</label>
                <input type="text" id="jobTitle" name="jobTitle" value={jobCompany} onChange={handleJobCompanyChange} required />
                <div className="bottomFormRow">
                    <div className="dateInput rowElem">
                        <label htmlFor="dob">Date of Birth</label>
                        <div className="dobRow">
                            <Select
                                className="daySelect"
                                value={dobDay}
                                styles={customStyles}
                                options={dayOptions()}
                                placeholder={'DD'}
                                onChange={(value) => setDOBDay(value)}
                                required />
                            <Select
                                className="daySelect"
                                value={dobMonth}
                                styles={customStyles}
                                options={monthOptions()}
                                placeholder={'MM'}
                                onChange={(value) => setDOBMonth(value)}
                                required />
                            <input className="daySelect numField" value={dobYear} type="number" id="dobYear" name="year" placeholder="YYYY" onChange={e => setDOBYear(e.target.value)} required />
                        </div>

                    </div>
                    <div className="rowElem">
                        <label htmlFor="maritalStatus">Marital Status</label>
                        <Select
                            options={maritalOptions}
                            styles={customStyles}
                            placeholder="Status"
                            value={maritalStatus}
                            isSearchable={false}
                            onChange={(option) => setMaritalStatus(option)}
                            onMenuOpen={handleDropdownOpen}
                            onMenuClose={handleDropdownClose}
                            required
                        />
                    </div>
                </div>
            </div>
        </form>;
    }

    const getForm2 = () => {
        return <form id="form2">
            <div className="formContainer">

                <h2>Contacts</h2>

                <div className="inputRow">
                    <img className="icon" src={require("../../assets/email.png")} />
                    <input type="text" id="email" name="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
                </div>
                <div className="bottomFormRow spacer">
                    <div className="rowElem">
                        <div className="inputRow">
                            <img className="icon" src={require("../../assets/phone.png")} />
                            <input type="text" id="phoneNumber" name="phoneNumber" value={phoneNumber} placeholder="Phone Number" onChange={handlePhoneChange} required />
                        </div>
                    </div>
                    <div className="rowElem">
                        <div className="inputRow">
                            <img className="icon" src={require("../../assets/phone.png")} />
                            <input type="text" id="phoneNumber2" name="phoneNumber2" value={phoneNumber2} placeholder="Second Phone Number (Optional)" onChange={handlePhone2Change} />
                        </div>
                    </div>
                </div>

                <h2>Social network</h2>
                <div className="bottomFormRow">
                    <div className="rowElem">
                        <Select
                            options={socialOptions}
                            styles={customStyles}
                            placeholder="Platform"
                            value={socialPlatform}
                            isSearchable={false}
                            onChange={(option) => setSocialPlatform(option)}
                            onMenuOpen={handleDropdownOpen}
                            onMenuClose={handleDropdownClose}
                        />
                    </div>
                    <div className="rowElem comments">
                        <input type="text" id="socialProfile" name="socialProfile" placeholder="Insert URL" value={socialProfile} onChange={handleSocialChange} />
                    </div>
                </div>
            </div>
        </form>;
    }

    const getForm3 = () => {
        return <form id="form3">
            <div className="formContainer">
                <div className="inputRow">
                    <div className="rowElem">
                        <label htmlFor="nationalID" >Egyptian National ID</label>
                        <input type="text" id="nationalID" name="nationalID" value={nationalID} onChange={handleNationalIDChange} required />
                    </div>
                    {maritalStatus['value'] === "married" && <div className="rowElem hrspacer">
                        <label htmlFor="spouseID" >Spouse ID</label>
                        <input type="text" id="spouseID" name="spouseID" value={spouseID} onChange={handleSpouseIDChange} required />
                    </div>}

                </div>

                <MyDropzone label="Egyptian National ID Photo (front & back)" maxFiles={3} toast={toastMe} files={idFiles} setFiles={setIDFiles} />

                {maritalStatus['value'] === "married" &&
                    <MyDropzone label="Spouse National ID Photo (front & back)" maxFiles={3} toast={toastMe} files={spouseIDFiles} setFiles={setSpouseIDFiles} />}

                {childrenAge['value'] === "over" &&
                    <MyDropzone label="Children National ID Photo" maxFiles={3} toast={toastMe} files={childrenIDFiles} setFiles={setChildrenIDFiles} />}

                <MyDropzone label="Family Photos" maxFiles={3} toast={toastMe} files={familyFiles} setFiles={setFamilyFiles} />

                <div className="bottomFormRow">
                    <div className="rowElem">
                        <label>Children</label>
                        <Select
                            options={ageOptions}
                            styles={customStyles}
                            placeholder="Select"
                            value={childrenAge}
                            isSearchable={false}
                            onChange={(option) => setChildrenAge(option)}
                            onMenuOpen={handleDropdownOpen}
                            onMenuClose={handleDropdownClose}
                            required
                        />
                    </div>
                    <div className="rowElem comments">
                        <label>Any Comments?</label>
                        <input type="text" id="socialProfile" name="socialProfile" value={comments} onChange={handleCommentChange} />
                    </div>
                </div>
            </div>
        </form>;
    }

    const validateForm1 = () => {
        if (dobYear > 2023 || dobYear < 1915 || (dobDay > 29 && dobMonth == 2) || (dobDay == 31 && (dobMonth == 4 || dobMonth == 6 || dobMonth == 9 || dobMonth == 11))) {
            toastMe("Please enter a valid date of birth");
            return false;
        }
        return true;
    }

    const validateForm2 = () => {
        var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        var facebookRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?facebook.com\/[a-zA-Z0-9]{1,}\/?/;
        var instagramRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?instagram.com\/[a-zA-Z0-9]{1,}\/?/;
        if (!email.match(emailRegex)) {
            toastMe("Please enter a valid email");
            return false;
        }
        if ((socialPlatform['value'] == "facebook" && !socialProfile.match(facebookRegex))
            || (socialPlatform['value'] === "instagram" && !socialProfile.match(instagramRegex))) {
            //toastMe("Please enter a valid social profile URL");
            return true;
        }
        return true;
    }

    const validateForm3 = () => {
        if (nationalID.length !== 14) {
            toastMe("Please enter a valid National ID");
            return false;
        }
        if (maritalStatus['value'] === "married" && spouseID.length !== 14) {
            toastMe("Please enter a valid Spouse National ID");
            return false;
        }
        if (idFiles.length < 2) {
            toastMe("Please provide at least 2 National ID photos (front and back sides)");
            return false;
        }
        if (maritalStatus['value'] === "married" && spouseIDFiles.length < 2) {
            toastMe("Please provide at least 2 Spouse National ID photo (front and back sides)");
            return false;
        }
        if (childrenAge['value'] === "over" && childrenIDFiles.length === 0) {
            toastMe("Please provide at least 1 Child National ID photo");
            return false;
        }
        if (familyFiles.length === 0) {
            toastMe("Please provide at least 1 Family Photo");
            return false;
        }
        return true;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const form = document.getElementById('form3');
        const valid = form.checkValidity();
        if (!valid) {
            toastMe();
            return;
        }
        if (!validateForm3()) return;
        setIsSubmitting(true);
        const idUrls = [];
        const spouseUrls = [];
        const childrenUrls = [];
        const familyUrls = [];

        try {

            const min = 10000000;
            const max = 99999999;
            let applicationNo = Math.floor(Math.random() * (max - min + 1)) + min;
            applicationNo += nationalID.slice(-4);

            for (let i = 0; i < idFiles.length; i++) {
                const file = idFiles[i];
                const imageExtension = file['path'].substring(file['path'].lastIndexOf('.'));
                const imageName = `${fullName} ID ${i + 1}${imageExtension}`;
                const imageRef = ref(storage, `${nationalID}/${applicationNo}/${imageName}`);
                await uploadBytesResumable(imageRef, file).then(async (snapshot) => {
                    const url = await getDownloadURL(snapshot.ref);
                    idUrls.push({ imageName, url });
                });
            }

            for (let i = 0; i < spouseIDFiles.length; i++) {
                const file = spouseIDFiles[i];
                const imageExtension = file['path'].substring(file['path'].lastIndexOf('.'));
                const imageName = `${fullName} Spouse ID ${i + 1}${imageExtension}`;
                const imageRef = ref(storage, `${nationalID}/${applicationNo}/${imageName}`);
                await uploadBytesResumable(imageRef, file).then(async (snapshot) => {
                    const url = await getDownloadURL(snapshot.ref);
                    spouseUrls.push({ imageName, url });
                });
            }

            for (let i = 0; i < childrenIDFiles.length; i++) {
                const file = childrenIDFiles[i];
                const imageExtension = file['path'].substring(file['path'].lastIndexOf('.'));
                const imageName = `${fullName} Children ID ${i + 1}${imageExtension}`;
                const imageRef = ref(storage, `${nationalID}/${applicationNo}/${imageName}`);
                await uploadBytesResumable(imageRef, file).then(async (snapshot) => {
                    const url = await getDownloadURL(snapshot.ref);
                    childrenUrls.push({ imageName, url });
                });
            }

            for (let i = 0; i < familyFiles.length; i++) {
                const file = familyFiles[i];
                const imageExtension = file['path'].substring(file['path'].lastIndexOf('.'));
                const imageName = `${fullName} Family Photo ${i + 1}${imageExtension}`;
                const imageRef = ref(storage, `${nationalID}/${applicationNo}/${imageName}`);
                await uploadBytesResumable(imageRef, file).then(async (snapshot) => {
                    const url = await getDownloadURL(snapshot.ref);
                    familyUrls.push({ imageName, url });
                });
            }

            const data = {};
            const dob = `${dobDay['value']} / ${dobMonth['value']} / ${dobYear}`;

            data[applicationNo] = {
                additionalComments: comments,
                dateCreated: Timestamp.fromDate(new Date(Date.now())),
                dateOfBirth: dob,
                district: props.district,
                email: email,
                fullName: fullName,
                jobAndCompany: jobCompany,
                maritalStatus: maritalStatus['label'],
                nationalId: nationalID,
                spouseId: spouseID,
                childrenAge: childrenAge['label'],
                phoneNumber: phoneNumber,
                project: props.project,
                secondPhoneNumber: phoneNumber2,
                socialHandle: socialProfile,
                socialPlatform: socialPlatform['label'],
                idUrls: idUrls.map(map => map["url"]),
                spouseUrls: spouseUrls.map(map => map["url"]),
                childrenUrls: childrenUrls.map(map => map["url"]),
                familyUrls: familyUrls.map(map => map["url"])
            };

            const emailHtml = EmailTemplate({ applicationNo, ...data[applicationNo] });
            const attachments = [
                ...idUrls.map((map) => { return { filename: map['imageName'], path: map['url'] } }),
                ...spouseUrls.map((map) => { return { filename: map['imageName'], path: map['url'] } }),
                ...childrenUrls.map((map) => { return { filename: map['imageName'], path: map['url'] } }),
                ...familyUrls.map((map) => { return { filename: map['imageName'], path: map['url'] } }),
            ];
            const emailData = { to: ["application@lavista.com.eg"], message: { html: emailHtml, subject: fullName, attachments: attachments } }

            const db = getFirestore(firebaseApp);
            await setDoc(doc(db, "users", nationalID), data, { merge: true });
            await addDoc(collection(db, "mail"), emailData);
            // navigate to success
            props.navigation.navigate("Success", { appNo: applicationNo });
        } catch (e) {
            toastMe("Could not submit form, please check your connection");
        }
        setIsSubmitting(false);
    }

    return (
        <div className="top">
            <form className="column" name="application" netlify>
                <img src={require("../../assets/logoBlack.png")} />
                <div className="progIndicators">
                    <span id="circle1" className="circle indicatorEnabled" />
                    <hr className={formStep >= 2 ? "indicatorEnabled" : ""} />
                    <span id="circle2" className={`circle ${formStep >= 2 ? "indicatorEnabled" : ""}`} />
                    <hr className={formStep >= 3 ? "indicatorEnabled" : ""} />
                    <span id="circle3" className={`circle ${formStep >= 3 ? "indicatorEnabled" : ""}`} />
                </div>
                <h1>Profile Info</h1>
                <p className="subtitle">Fill in the data below. It will take a couple of minutes.</p>
                {formStep == 1 && getForm1()}
                {formStep == 2 && getForm2()}
                {formStep == 3 && getForm3()}
                <div className="buttonRow">
                    <button onClick={(e) => onPrevious(e)} >
                        <img className="arr-left" src={require("../../assets/arrow-left.png")} />
                        Previous
                    </button>
                    {formStep !== 3 && <button onClick={(e) => onNext(e, `form${formStep}`)}>
                        Next
                        <img className="arr-right" src={require("../../assets/arrow-right.png")} />
                    </button>}
                    {formStep === 3 && <button className="submitButton" type="submit" onClick={(e) => onSubmit(e)}>
                        {isSubmitting ?
                            <CircularProgress color="inherit" size={25} thickness={5}/>
                            :
                            <>
                                Submit
                                <img className="tickImg arr-right" src={require("../../assets/tick.png")} />
                            </>
                        }

                    </button>}
                </div>
            </form>
        </div>
    );


}

const MyDropzone = (props) => {
    const files = props.files;
    const setFiles = props.setFiles;
    const onDrop = useCallback(acceptedFiles => {
        if (files.length + acceptedFiles.length > props.maxFiles) {
            props.toast(`Upload a maximum of ${props.maxFiles} files`);
            return;
        }
        if (acceptedFiles?.length) {
            console.log(Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) }));
            setFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))]
            );
        }
    })

    const rejectFile = () => {
        props.toast(`Only .PNG and .JPG files are accepted`);
        return;
    }


    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    return <div className="myDropzone">
        <label>{props.label}</label>
        <Dropzone onDrop={onDrop} onDropRejected={rejectFile} accept={{ 'image/png': ['png'], 'image/jpeg': ['jpg'] }} >
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {files.length > 0 && <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>}
                        {files.length == 0 && <><div>CLICK OR DRAG FILES TO UPLOAD</div>
                            <div>Upload up to 3 photos</div></>
                        }

                        <hr className="dropzoneDivider" />
                    </div>

                </section>
            )}
        </Dropzone>
    </div>;
}



const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#f5f5f5',
        border: 'none',
        borderBottom: '1px solid #E2E4E5',
        textAlign: 'start'
    }),

    placeholder: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#000' : '#888',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        transition: 'transform 0.3s',
        transform: 'rotate(0deg)',
        borderLeft: 'none',
    }),
    indicatorSeparator: () => ({}),
    menu: (provided) => ({
        ...provided,
        borderRadius: '20px',
    }),
    option: (provided, state) => ({
        ...provided,
        borderRadius: '14px',
        fontSize: '14px',
        fontWeight: state.isFocused ? "500" : "400",
        color: state.isFocused ? "black" : "#666666",
        textAlign: "left",
        backgroundColor: "transparent"
    }),
    value: (provided) => ({
        ...provided,
        borderRadius: '20px',
        backgroundColor: 'transparent'
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        backgroundColor: "transparent"
    }),
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "3px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: '3px',
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};