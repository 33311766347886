import "./Success.css";

const Success = (props) => {
    return  <div className="successContainer">
            <img className="successImg" src={require("../../assets/logoBlack.png")} />
            <div>
                <div className="successHeader">THANK YOU</div>
                <div className="successTitle">Your Form Has Been Submitted</div>
                <div className="successSubtitle">Your application number is #{props.appNo}</div>
            </div>
            <img className="successImg" src={require("../../assets/logo2.png")} />
        </div>
    
}

export default Success;