import React from "react";

const EmailTemplate = (formData) => {
  const {
    applicationNo,
    fullName,
    jobAndCompany,
    district,
    project,
    dateOfBirth,
    maritalStatus,
    email,
    phoneNumber,
    secondPhoneNumber,
    socialPlatform,
    socialHandle,
    nationalId,
    spouseId,
    childrenAge,
    additionalComments,
    idUrls,
    familyUrls,
    spouseUrls,
    childrenUrls
  } = formData;

  
  const htmlTemplate = `
  <html>
  <head>
    <style>
      body {
        font-family: "Google Sans", 'Roboto', Helvetica;
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      .bgColor {
        background-color: #f5f5f5;
        padding-top: 50px;
        padding-bottom: 50px;
      }

      td {
        padding: 10px;
        border: none;
      }

      table.formContainer td {
        width: 100%;
      }
      table.formContainer tbody {
        min-width: 600px;
      }
      table.formContainer tr {
        display: flex;
      }

      p {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }
      
      .subtitle {
        font-size: 18px;
        color: #242731;
        font-weight: 600;
      }

      .top {
        font-family: "Google Sans", 'Roboto', Helvetica;
        width: 600px;
        border: none;
        margin: auto;
      }
      h1 {
        font-weight: 700;
        color: #242731;
        font-size: 28px;
        line-height: 36px;
        margin-top: 20px;
        margin-bottom: 16px;
      }
      h2 {
        color: #242731;
        margin-top: 0px;
        margin-bottom: 15px;
      }
      .formContainer {
        display:flex;
        max-width: 600px;
        padding: 14px;
        margin-bottom: 32px;
        border: 1px solid #e2e4e5;
        border-radius: 8px;
      }
      label {
        color: #242426;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: block;
        text-align: left;
      }
    </style>
  </head>
  <body>
    <div class="bgColor">
      <table class="top">
        <tbody>
          <tr>
            <img
              width="100"
              src="https://firebasestorage.googleapis.com/v0/b/lavista-12948.appspot.com/o/assets%2FlogoBlack.png?alt=media&token=bb61ef7e-088d-40cd-b05c-9f9b9c27f3db"
            />
          </tr>
          <tr>
        <table style="max-width: 600px;">
          <tr>
            <td>
            <h1>Application No.<br />${applicationNo}</h1>
            </td>
            <td style="text-align: end; padding-top: 30px;">
              <div class="subtitle">${district}</div>
              <div class="subtitle">${project}</div>
            </td>
          </tr>
        </table>
    </tr>
          <tr>
            <table class="formContainer">
              <tr>
                <td>
                  <h2>Personal Data</h2>
                  <table>
                    <tr>
                      <td>
                        <div><label>Full Name</label></div>
                        <p>${fullName}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Job Title / Company Name</label></div>
                        <p>${jobAndCompany}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Date of Birth</label></div>
                        <p>${dateOfBirth}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Marital Status</label></div>
                        <p>${maritalStatus}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>National ID</label></div>
                        <p>${nationalId}</p>
                      </td>
                    </tr>
                    ${spouseId !== "" ? 
                    `<tr>
                      <td>
                        <div><label>Spouse ID</label></div>
                        <p>${spouseId}</p>
                      </td>
                    </tr>`
                    : ``}
                    <tr>
                      <td>
                        <div><label>Children Age</label></div>
                        <p>${childrenAge}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Comments</label></div>
                        <p>${additionalComments}</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </tr>
          <tr>
            <table class="formContainer">
              <tr>
                <td>
                  <h2>Contact Info</h2>
                  <table>
                    <tr>
                      <td>
                        <div><label>Email</label></div>
                        <p>${email}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>${socialPlatform}</label></div>
                        <p>${socialHandle}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Phone Number</label></div>
                        <p>${phoneNumber}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><label>Additional Phone Number</label></div>
                        <p>${secondPhoneNumber}</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </tr>
          <tr>
            <table class="formContainer">
              <tr>
                <td>
                  <h2>National ID</h2>
                  <table>
                    <tr style="display: inline-flex;">
                      ${idUrls.map(url => `<td>
                        <a target="_blank" href="${url}">
                              <img width="140" src="${url}"/>
                          </a>
                      </td>`).join('')}
                    </tr>
                  </table>
                </td>
              </tr>
              ${spouseUrls.length !== 0 ?
              `<tr>
                <td>
                  <h2>Spouse ID</h2>
                  <table>
                    <tr style="display: inline-flex;">
                      ${spouseUrls.map(url => `<td>
                        <a target="_blank" href="${url}">
                              <img width="140" src="${url}"/>
                          </a>
                      </td>`).join('')}
                    </tr>
                  </table>
                </td>
              </tr>` : ``
              }
              ${childrenUrls.length !== 0 ?
                `<tr>
                  <td>
                    <h2>Children ID</h2>
                    <table>
                      <tr style="display: inline-flex;">
                        ${childrenUrls.map(url => `<td>
                          <a target="_blank" href="${url}">
                                <img width="140" src="${url}"/>
                            </a>
                        </td>`).join('')}
                      </tr>
                    </table>
                  </td>
                </tr>` : ``
                }
              <tr>
                  <td>
                    <h2>Family Photos</h2>
                    <table>
                      <tr style="display: inline-flex;">
                      ${familyUrls.map(url => `<td>
                        <a target="_blank" href="${url}">
                              <img width="140" src="${url}"/>
                          </a>
                      </td>`).join('')}
                      </tr>
                    </table>
                  </td>
                </tr>
            </table>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</html>
      
        `;

  return htmlTemplate;
};

export default EmailTemplate;